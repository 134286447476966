import './App.css';
import React, {useEffect} from 'react';
import {
  useRoutes,
  useLocation
} from "react-router-dom";
import Welcome from './views/Welcome/Welcome';
import Nickname from './views/Nickname/Nickname';
import Loading from './views/Loading/Loading';
import Quiz from './views/Quiz/Quiz';
import Success from './views/Success/Success';
import Failure from './views/Failure/Failure';
import { trackPageView } from '../src/utils/analytics';

function App() {
  const location = useLocation();

  useEffect(() => {
    trackPageView(location.pathname + location.search);
  }, [location]);

  const routes = useRoutes([
    { path: '/', element: (<div className='App'><Welcome/></div>) },
    { path: '/nickname', element: (<div className='App'><Nickname/></div>) },
    { path: '/loading', element: (<div className='App'><Loading/></div>) },
    { path: '/quiz', element: (<div className='App'><Quiz/></div>) },
    { path: '/success', element: (<div className='App'><Success/></div>) },
    { path: '/failure', element: (<div className='App'><Failure/></div>) },
  ]);

  return routes;
}

export default App;
