import styled from "styled-components";

export const MainDiv = styled.div`
    display: flex;
    flex-direction: column;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
`;

export const ScreenshotDiv = styled.div`
    width: fit-content;
`;

export const Content = styled.div`
    flex: 1;
`;

export const SongTitleDiv = styled.div`
    flex: 1;
`;

export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent; 
    padding: 0px;
    margin-left: 10px;
    margin-right: 10px;
`;

export const TitleSection = styled.div`
    display: flex;
    flex-direction: column; /* Arrange text vertically */
`;

export const TitleText = styled.p`
    font-family: 'ArcadeFont', sans-serif; /* Assuming custom font */
    color: #ffffff; 
    font-size: 0.8rem; /* Adjust font size */
    margin: 0px;
    padding: 0px;
    margin-right: 10px;
    white-space: pre-wrap;
    -webkit-background-clip: text;
    -webkit-text-fill-color: #ffffff; 
    background-clip: text; /* For Firefox */
`;

export const TitleSpan = styled.span`
    font-family: 'ArcadeFont', sans-serif; /* Assuming custom font */
    color: #00ff00; /* Green color matching image */
    font-size: 0.8rem; /* Adjust font size */
    margin: 0;
    padding: 0;
    margin-right: 10px;
    width: auto;
    white-space: pre-wrap;
    background: linear-gradient(90deg, #60BB5F, #229A03);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; 
    background-clip: text; /* For Firefox */
    color: transparent; /* Makes the text itself transparent */
`;

export const SubtitleText = styled.p`
    font-family: 'ArcadeFont', sans-serif;
    color: #60BB5F;
    font-size: 0.5rem;
    margin: 0;
    margin-top: 0.31rem;
    text-transform: uppercase;
`;

export const InfoDiv = styled.div`
    cursor: pointer;
    height: 100%; /* Matches the height of the parent div */
    display: flex; /* Allows the div to expand fully */
    align-items: flex-start;
`;

export const NumSpan = styled.span`
    color: white;
    font-size: small;
`;

export const OptionContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const OptionDiv = styled.div`
    padding: 0.63rem;
    border: none;
    background-color: #60BB5F;
    font-size: 0.7rem;
    border-radius: 1.25rem;
    outline: none;
    width: 18.75rem;
    color: white;
    cursor: pointer;
    margin-left: 0.63rem;
    margin-right: 0.63rem;
`;

export const QuestionContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent; 
    padding: 0px;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
`;

export const QuestionText = styled.p`
    font-family: 'ArcadeFont', sans-serif; /* Custom font */
    color: white; 
    font-size: 0.8rem; /* Adjust font size as needed */
    text-align: center; /* Center align the text */
    margin: 0; /* Remove default margin */
    margin-top: -7px;
    margin-bottom: -12px;
    white-space: pre-wrap; /* Preserve whitespace and allow text to wrap */
    position: relative; /* Ensure it sits above the border */
    z-index: 1; /* Bring text above the border */
    background-color: inherit;
`;

export const HighlightedInfoDiv = styled(InfoDiv)`
    animation: highlight 0.5s infinite alternate;
    @keyframes highlight {
        0% {
            transform: scale(1);
            opacity: 1;
        }
        100% {
            transform: scale(1.5);
            opacity: 0.8;
        }
    }
`;
