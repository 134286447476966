import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import {
    useNavigate,
    useLocation
} from "react-router-dom";
import { 
    Content, 
    MainDiv, 
    SmileyContainer,
    SmileyImage,
    CongratsText,
    NameSection,
    NameText,
    WinText,
    UL,
    LI,
    CenteredDiv
} from './FailureStyles';
import MainButton from '../../components/Buttons/MainButton';
import Logo from "../../components/Logo/Logo";
import Footer from '../../components/Footer/Footer';
import Smiley1 from '../../images/fail1.svg';
import Smiley2 from '../../images/fail2.svg';
import Smiley3 from '../../images/fail3.svg';

const Failure = () => {
    const [currentUser, setCurrentUser] = useState({});
    const navigate = useNavigate();
    const location = useLocation();
    const lastOutcome = location.state?.lastOutcome
    const [ locationKeys, setLocationKeys ] = useState([]);

    const getBaseUrl = useCallback(() => {
        const isProduction = process.env.NODE_ENV === 'production';
        if (isProduction) {
            return 'https://banggz-quiz-5bafa8062821.herokuapp.com';
        } else {
            // Detect if you're accessing from localhost or a local network
            const isLocalhost = window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1';
            return isLocalhost ? 'http://localhost:8000' : 'http://10.0.0.131:8000';
        }
    }, []);

    const navToWelcomePage = useCallback(() => {
        navigate('/');
    }, [navigate]);

    const getCurrentUser = useCallback(() => {
        axios.post(
            `${getBaseUrl()}/api/user/get`,
            {
                user_id: localStorage.getItem("userId").replace(/^"+|"+$/g, ''),
            },
            { withCredentials: true }
        ).then(res => {
            setCurrentUser(res.data);
        }).catch(err => {
            console.log(err);
        });
    }, [getBaseUrl]);
    
    useEffect(() => {
        if (!locationKeys.includes(location.key)) {
            setLocationKeys([location.key]);
        }

        const handleLocationChange = () => {
            if (window.history.state && window.history.state.idx) {
                if (window.history.state.idx < locationKeys.length) {
                } else {
                }
            }
        };

        window.addEventListener('popstate', handleLocationChange);

        return () => {
            window.removeEventListener('popstate', handleLocationChange);
        };
    }, [locationKeys, location.key]);

    useEffect(() => {
        if(localStorage.getItem("userId") === null){
            navToWelcomePage();
        } else {
            if(lastOutcome === 'failure'){
                getCurrentUser();
            } else {
                navToWelcomePage();
            }
        }

        const handleBackNavigation = () => {
            navToWelcomePage(); 
        };

        window.history.pushState(null, '', window.location.href);
        window.addEventListener('popstate', handleBackNavigation);

        return () => {
            window.removeEventListener('popstate', handleBackNavigation);
        };
    }, [navToWelcomePage, getCurrentUser, lastOutcome]);

    const trackTryAgainButtonClicked = () => {
        window.gtag('event', 'try_again_button_click', {
            event_category: 'Failure',
            event_label: 'Try again button clicked',
        });
    };

    const retakeClicked = () => {
        trackTryAgainButtonClicked();
        navToWelcomePage();
    }

    return (
        <MainDiv>
            <Content>
                <Logo 
                    width="20rem"
                    $margintop="0rem"
                    $marginbottom="0rem"
                    $bottom="0.5rem"
                    $fontSize="1.69rem"
                />
                <br/>
                <br/>
                <SmileyContainer>
                    <SmileyImage src={Smiley1} alt="Smiley 1" />
                    <SmileyImage src={Smiley2} alt="Smiley 2" />
                    <SmileyImage src={Smiley3} alt="Smiley 3" />
                </SmileyContainer>
                <CongratsText>Sorry!</CongratsText>
                <NameSection>
                    <NameText>{currentUser.nickname ? currentUser.nickname : "Bangger"}</NameText>
                </NameSection>
                <WinText>You almost won &#8358;4000</WinText>
                <br/>
                <MainButton
                    color="#FFFFFF"
                    text="Try Again"
                    width="270px"
                    height="40px"
                    type="submit"
                    disabled={false}
                    onClick={retakeClicked}
                />
                <br/>
                <CenteredDiv>
                    <UL>
                        <LI>Answer 4 questions for &#8358;4000</LI>
                        <LI>Listen to the referenced song to answer</LI>
                        <LI>You will be contacted via email within 24|hours</LI>
                    </UL>
                </CenteredDiv>
            </Content>
            <Footer/>
        </MainDiv>
    );
}

export default Failure;