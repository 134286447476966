import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import {
    useNavigate,
    useLocation
} from "react-router-dom";
import Logo from "../../components/Logo/Logo";
import PopUp from "../../components/PopUp/PopUp";
import Footer from '../../components/Footer/Footer';
import { 
    OptionDiv, 
    Content, 
    MainDiv, 
    OptionContainer,
    Container,
    TitleSection,
    TitleText,
    SubtitleText,
    InfoDiv,
    NumSpan,
    QuestionContainer,
    QuestionText,
    TitleSpan,
    HighlightedInfoDiv
} from './QuizStyles';
import {ReactComponent as Info} from '../../images/info.svg';
import {ReactComponent as Dot} from '../../images/dot.svg';

const Quiz = (props) => {
    const [currentUser, setCurrentUser] = useState({});
    const [currentQuestion, setCurrentQuestion] = useState({});
    const [currentSong, setCurrentSong] = useState({});
    const [questionCount, setQuestionCount] = useState(0);
    const [option1, setOption1] = useState("");
    const [option2, setOption2] = useState("");
    const [option3, setOption3] = useState("");
    const [option4, setOption4] = useState("");
    const [excludedSongs, setExcludedSongs] = useState([]);
    const [questionAttempts, setQuestionAttempts] = useState([]);
    const [lastOutcome, setLastOutcome]= useState("");
    const [isPopupVisible, setPopupVisible] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const [ locationKeys, setLocationKeys ] = useState([]);
    const [quizTime, setQuizTime] = useState(0);
    const [quizTimerIsActive, setQuizTimerIsActive] = useState(false);
    const [questionTime, setQuestionTime] = useState(0);
    const [questionTimerIsActive, setQuestionTimerIsActive] = useState(false);
    const [isQuizEnding, setIsQuizEnding] = useState(false);
    const [hasViewedInfo, setHasViewedInfo] = useState(false); 

    const getBaseUrl = useCallback(() => {
        const isProduction = process.env.NODE_ENV === 'production';
        if (isProduction) {
            return 'https://banggz-quiz-5bafa8062821.herokuapp.com';
        } else {
            const isLocalhost = window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1';
            return isLocalhost ? 'http://localhost:8000' : 'http://10.0.0.131:8000';
        }
    }, []);

    const navToWelcomePage = useCallback(() => {
        navigate('/');
    }, [navigate]);

    const navToSucessPage = useCallback(() => {
        navigate('/success', { state: { lastOutcome: lastOutcome } });
    }, [navigate, lastOutcome]);

    const navToFailurePage = useCallback(() => {
        navigate('/failure', { state: { lastOutcome: lastOutcome } });
    }, [navigate, lastOutcome]);

    const togglePopup = () => {
        setPopupVisible(!isPopupVisible);
    };

    const startQuizTimer = useCallback(() => {
        setQuizTimerIsActive(true);
    }, []);

    const stopQuizTimer = useCallback(() => {
        setQuizTimerIsActive(false);
    }, []);

    const resetQuizTimer = useCallback(() => {
        setQuizTimerIsActive(false);
        setQuizTime(0);
    }, []);

    const startQuestionTimer = useCallback(() => {
        setQuestionTimerIsActive(true);
    }, []);

    const stopQuestionTimer = useCallback(() => {
        setQuestionTimerIsActive(false);
    }, []);

    const resetQuestionTimer = useCallback(() => {
        setQuestionTimerIsActive(false);
        setQuestionTime(0);
    }, []);

    const stopAllTimers = useCallback(() => {
        setIsQuizEnding(true);
        stopQuizTimer();
        stopQuestionTimer();
    }, [stopQuizTimer, stopQuestionTimer]);

    const getCurrentUser = useCallback(() => {
        axios.post(
            `${getBaseUrl()}/api/user/get`,
            {
                user_id: localStorage.getItem("userId").replace(/^"+|"+$/g, ''),
            },
            { withCredentials: true }
        ).then(res => {
            setCurrentUser(res.data);
        }).catch(err => {
            console.log(err);
        });
    }, [getBaseUrl]);

    const endQuiz = useCallback(() => {
        if (lastOutcome === "success") {
            navToSucessPage();
        } else {
            navToFailurePage();
        }
    }, [lastOutcome, navToSucessPage, navToFailurePage]);

    const sendOutcomeEmail = useCallback((successfulQuizAttempts) => {
        axios.post(
            `${getBaseUrl()}/api/quizAttempt/sendOutcomeEmail`,
            {
                email: currentUser.email,
                nickname: currentUser.nickname,
                outcome: lastOutcome,
                numberOfQuestionsAttempted: questionAttempts.length,
                successfulQuizAttempts: successfulQuizAttempts
            },
            { withCredentials: true }
        ).then(res => {
            console.log("Email successfully sent.")
        }).catch(err => {
            console.log(err);
        });
    }, [getBaseUrl, currentUser, lastOutcome, questionAttempts])

    const countSuccessfulQuizAttemptsForUser = useCallback(() => {
        axios.post(
            `${getBaseUrl()}/api/quizAttempts/countSuccessfulForUser`,
            {
                user_id: currentUser._id,
            },
            { withCredentials: true }
        ).then(res => {
            sendOutcomeEmail(res.data);
        }).catch(err => {
            console.log(err);
        });
    }, [getBaseUrl, sendOutcomeEmail, currentUser])

    const createQuizAttempt = useCallback(() => {
        console.log(quizTime);
        stopAllTimers();
        axios.post(
            `${getBaseUrl()}/api/quizAttempt/create`,
            {
                user_id: currentUser._id,
                attempt_ids: questionAttempts,
                outcome: lastOutcome,
                summary: `${currentUser.email} (${currentUser.nickname}) attempted ${questionAttempts.length} ${questionAttempts.length > 1 ? "questions" : "question"} and was ${lastOutcome === "success" ? "successful" : "unsuccessful"}.`,
                completionTime: quizTime
            },
            { withCredentials: true }
        ).then(res => {
            resetQuizTimer();
            countSuccessfulQuizAttemptsForUser();
            endQuiz();
        }).catch(err => {
            console.log(err);
        });
    }, [getBaseUrl, stopAllTimers, resetQuizTimer, countSuccessfulQuizAttemptsForUser, endQuiz, quizTime, currentUser, questionAttempts, lastOutcome]);

    const getRandomQuestion = useCallback(() => {
        axios.post(
            `${getBaseUrl()}/api/question/getRandom`,
            {
                excludedSongs: excludedSongs,
            },
            { withCredentials: true }
        ).then(async (res) => {
            const question = res.data;

            const songResponse = await axios.post(
                `${getBaseUrl()}/api/song/get`,
                { song_id: question.song_id },
                { withCredentials: true }
            );

            const song = songResponse.data;

            setCurrentQuestion(question);
            setCurrentSong(song);
            setQuestionCount(prevCount => prevCount + 1);
            setHasViewedInfo(false);
            setOption1(question.option1);
            setOption2(question.option2);
            setOption3(question.option3);
            setOption4(question.option4);
            setExcludedSongs([...excludedSongs, question.song_id]);
            startQuestionTimer();
        }).catch(err => {
            console.log(err);
        });
    }, [getBaseUrl, startQuestionTimer, excludedSongs]);

    useEffect(() => {
        if (!locationKeys.includes(location.key)) {
            setLocationKeys([location.key]);
        }

        const handleLocationChange = () => {
            if (window.history.state && window.history.state.idx) {
                if (window.history.state.idx < locationKeys.length) {
                } else {
                }
            }
        };

        window.addEventListener('popstate', handleLocationChange);

        return () => {
            window.removeEventListener('popstate', handleLocationChange);
        };
    }, [locationKeys, location.key]);

    useEffect(() => {
        if(localStorage.getItem("userId") === null){
            navToWelcomePage();
        } else {
            getCurrentUser();
        }

        const handleBackNavigation = () => {
            navToWelcomePage();
        };

        window.history.pushState(null, '', window.location.href);
        window.addEventListener('popstate', handleBackNavigation);

        return () => {
            window.removeEventListener('popstate', handleBackNavigation);
        };
    }, [navToWelcomePage, getCurrentUser, navigate]);

    useEffect(() => {
        let interval;
    
        if (quizTimerIsActive && !isQuizEnding) {
            interval = setInterval(() => {
                setQuizTime(prevTime => prevTime + 1);
            }, 1000);
        } else if ((!quizTimerIsActive || isQuizEnding) && quizTime !== 0) {
            clearInterval(interval);
        }
    
        return () => clearInterval(interval);
    }, [quizTimerIsActive, quizTime, isQuizEnding]);
    
    useEffect(() => {
        let interval;
    
        if (questionTimerIsActive && !isQuizEnding) {
            interval = setInterval(() => {
                setQuestionTime(prevTime => prevTime + 1);
            }, 1000);
        } else if ((!questionTimerIsActive || isQuizEnding) && questionTime !== 0) {
            clearInterval(interval);
        }
    
        return () => clearInterval(interval);
    }, [questionTimerIsActive, questionTime, isQuizEnding]);

    useEffect(() => {
        if(questionCount < 1) {
            resetQuizTimer();
            resetQuestionTimer();
            getRandomQuestion();
            startQuizTimer();
        }
    }, [questionCount, getRandomQuestion, resetQuizTimer, resetQuestionTimer, startQuizTimer]);

    useEffect(() => {
        if(questionAttempts.length > 3 || lastOutcome === "failure") {
            stopAllTimers();
            createQuizAttempt();
        }
    }, [questionAttempts, lastOutcome, stopAllTimers, createQuizAttempt]);

    const createQuestionAttempt = (outcome, selectedOption) => {
        console.log(questionTime);
        axios.post(
            `${getBaseUrl()}/api/questionAttempt/create`,
            {
                user_id: currentUser._id,
                question_id: currentQuestion._id,
                outcome: outcome,
                completionTime: questionTime
            },
            {withCredentials: true}
        ).then(res => {
            setLastOutcome(outcome);
            setQuestionAttempts([...questionAttempts, res.data._id]);

            if (outcome === "success") {
                window.gtag('event', 'question_success', {
                    event_category: 'Quiz',
                    event_label: `User succeeded on Question: ${currentQuestion.question} for Song: ${currentSong.title}`,
                    song_title: currentSong.title,
                    question: currentQuestion.question,
                    selected_option: currentQuestion.answer,
                    completionTime: questionTime
                });
            } else if (outcome === "failure") {
                window.gtag('event', 'question_failure', {
                    event_category: 'Quiz',
                    event_label: `User failed on Question: ${currentQuestion.question} for Song: ${currentSong.title}`,
                    song_title: currentSong.title,
                    question: currentQuestion.question,
                    selected_option: currentQuestion.answer,
                    attempted_option: selectedOption,
                    completionTime: questionTime
                });
            }
            
            resetQuestionTimer();
        }).catch(err => {
            console.log(err);
        });
    }

    const optionSelected = (selectedOption) => {
        stopQuestionTimer();
        if(selectedOption === currentQuestion.answer){
            createQuestionAttempt("success", selectedOption);
            if(questionCount < 4) {
                getRandomQuestion();
            }
        } else {
            createQuestionAttempt("failure", selectedOption);
        }
    }

    useEffect(() => {
        if (currentQuestion && currentSong.title) {
            window.gtag('event', 'view_question', {
                event_category: 'Quiz',
                event_label: `Song: ${currentSong.title}, Question: ${currentQuestion.question}`,
                song_title: currentSong.title,
                question: currentQuestion.question
            });
        }
    }, [currentQuestion, currentSong.title]);

    const trackOptionButtonClicked = (optionNumber, selectedOption) => {
        window.gtag('event', 'quiz_option_selected', {
            event_category: 'Quiz',
            event_label: `Option ${optionNumber} Selected`,
            song_title: currentSong.title,
            question: currentQuestion.question,
            selected_option: selectedOption,
            correct_answer: currentQuestion.answer
        });
    };

    const handleOption1Clicked = () => {
        trackOptionButtonClicked(1, option1);
        optionSelected(option1);
    };

    const handleOption2Clicked = () => {
        trackOptionButtonClicked(2, option2);
        optionSelected(option2);
    };

    const handleOption3Clicked = () => {
        trackOptionButtonClicked(3, option3);
        optionSelected(option3);
    };

    const handleOption4Clicked = () => {
        trackOptionButtonClicked(4, option4);
        optionSelected(option4);
    };

    const trackInfoButtonClicked = () => {
        window.gtag('event', 'info_button_clicked', {
            event_category: 'Quiz',
            event_label: `Info for ${currentSong.title} clicked`,
            song_title: currentSong.title,
        });
    };

    const infoButtonClicked = () => {
        setHasViewedInfo(true);
        trackInfoButtonClicked();
        togglePopup();
    };

    return (
        <MainDiv>
            <Content>
                <Logo 
                    width="20rem"
                    $margintop="0rem"
                    $marginbottom="0rem"
                    $bottom="0.5rem"
                    $fontSize="1.69rem"
                />
                <br/>
                <NumSpan>#{questionCount}</NumSpan>
                <br/>
                <br/>
                <Container>
                    <TitleSection>
                        <TitleText><TitleSpan>SONG TITLE:</TitleSpan><span>{currentSong.title}</span></TitleText>
                    </TitleSection>
                    {hasViewedInfo ? (
                        <InfoDiv onClick={infoButtonClicked}>
                            <Info width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"/>
                        </InfoDiv>
                    ) : (
                        <HighlightedInfoDiv onClick={infoButtonClicked}>
                            <Info width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"/>
                        </HighlightedInfoDiv>
                    )}
                </Container>
                {isPopupVisible && (
                    <PopUp 
                        togglePopup={togglePopup}
                        info={currentSong.info}
                    />
                )}
                <Container>
                    <TitleSection>
                        <SubtitleText>4 THE BANGGERZ</SubtitleText>
                    </TitleSection>
                    &nbsp;
                    <Dot width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg"/>
                    &nbsp;
                    <TitleSection>
                        <SubtitleText>MUSIC BY BANGGZ</SubtitleText>
                    </TitleSection>
                </Container>
                <br/>
                {
                    currentQuestion &&
                    <div>
                        <QuestionContainer>
                            <QuestionText>
                                {currentQuestion.question}
                            </QuestionText>
                        </QuestionContainer>
                        <br/>
                        {
                            currentQuestion.option1 &&
                            <OptionContainer>
                                <OptionDiv onClick={handleOption1Clicked}>{currentQuestion.option1}</OptionDiv>
                            </OptionContainer>
                        }
                        <br/>
                        {
                            currentQuestion.option2 &&
                            <OptionContainer>
                                <OptionDiv onClick={handleOption2Clicked}>{currentQuestion.option2}</OptionDiv>
                            </OptionContainer>
                        }
                        <br/>
                        {
                            currentQuestion.option3 &&
                            <OptionContainer>
                                <OptionDiv onClick={handleOption3Clicked}>{currentQuestion.option3}</OptionDiv>
                            </OptionContainer>
                        }
                        <br/>
                        {
                            currentQuestion.option4 &&
                            <OptionContainer>
                                <OptionDiv onClick={handleOption4Clicked}>{currentQuestion.option4}</OptionDiv>
                            </OptionContainer>
                        }
                    </div>
                }
                <br/>
            </Content>
            <Footer/>
        </MainDiv>
    );
}

export default Quiz;