import styled, { keyframes } from "styled-components";

const swellUp = keyframes`
    0% {
        transform: scale(0.1); /* Start smaller */
    }
    100% {
        transform: scale(1); /* End at full size */
    }
`;

export const ImageContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 100%;
    position: relative;
    display: inline-block;
    animation: ${swellUp} 0.5s ease-out forwards;
`;

export const FloppyImage = styled.img`
    width: ${props => props.width};
    height: auto;
    margin-top: ${props => props.$margintop};
    margin-bottom: ${props => props.$marginbottom};
`;

export const LogoText = styled.div`
    position: absolute;
    bottom: ${props => props.$bottom}; 
    left: 50%;
    transform: translateX(-50%);
    color: white;
    font-family: 'ArcadeFont', sans-serif;
    font-size: ${props => props.$fontSize}; 
    width: 100%;
    text-align: center;
`;