import React, {useState} from 'react';
import { 
    InputContainer,
    TextInput,
    TopText
} from './InputStyles';

const Input = ({
    name,
    type,
    value,
    onChange,
    width,
    height,
    $topinfo,
    $othertopinfo,
    $topinfofontsize,
    $topinfocolor
}) => {
    const [isfocused, setIsFocused] = useState(false);

    const handleInputChange = (e) => {
        onChange(e); 
    };

    const handleInputFocus = () => {
        setIsFocused(true); 
    };

    const handleInputBlur = () => {
        setIsFocused(false);
    };

    return (
        <InputContainer>
            <TopText
                $topinfofontsize={$topinfofontsize}
                $topinfocolor={$topinfocolor}
                value={value}
            >
                {value ? $othertopinfo : $topinfo}
            </TopText>
            <TextInput
                required
                name={name}
                type={type}
                onChange={handleInputChange}
                onFocus={handleInputFocus}
                onBlur={handleInputBlur}
                value={value}
                width={width}
                height={height}
                $isfocused={isfocused}
            />
        </InputContainer>
    )
}

export default Input;
