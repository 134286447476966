import styled from "styled-components";
import InactiveButton from '../../images/inactive-button.svg';
import ActiveButton from '../../images/active-button.svg';

export const AButton = styled.button`
    background-image: url(${props => props.disabled ? InactiveButton : ActiveButton});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    background-color: transparent;
    width: ${props => props.width};
    height: ${props => props.height};
    color: ${props => props.color};
    padding: 5px;
    border: none;
    cursor: pointer;
    font-family: 'ArcadeFont', sans-serif;
    font-size: 15px;

    &:disabled {
        cursor: not-allowed;
    }
`;

export const ButtonText = styled.span`
    opacity: ${props => props.disabled ? 0.5 : 1};
`;