import React, {useCallback } from 'react';
import FloppyDiskImage from '../../images/Floppy.png';
import { ImageContainer, FloppyImage, LogoText } from './LogoStyles';
import {
  useNavigate
} from "react-router-dom";

const Logo = (props) => {
  const navigate = useNavigate();

  const navToWelcomePage = useCallback(() => {
    navigate('/');
  }, [navigate]);

  return (
    <ImageContainer onClick={navToWelcomePage}>
      <FloppyImage 
        src={FloppyDiskImage} alt="4tb quiz Logo"
        width={props.width}
        $margintop={props.$margintop}
        $marginbottom={props.$marginbottom}
        loading="lazy"
        type="image/webp"
      />
      <LogoText
        $bottom={props.$bottom}
        $fontSize={props.$fontSize}
      >4 FOR 4</LogoText>
    </ImageContainer>
  )
}

export default Logo;
