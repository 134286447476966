import styled from 'styled-components';
import BG from '../../images/popup-bg.svg';

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  //background-color: transparent;
  background-color: rgba(0, 0, 0, 0.7); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* High z-index to ensure it covers other elements */
`;

export const PopupBox = styled.div`
  background-image: url(${BG});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center; 
  padding: 20px;
  width: 290px;
  height: 205px;
  color: white;
  font-size: small;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
`;

export const Button = styled.button`
  padding: 10px 20px;
  background-color: #229a03;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #1e8502;
  }
`;

// Container div with 100% width
export const FullWidthDiv = styled.div`
  width: 100%; /* Full width */
  position: relative; /* Position relative to enable absolute positioning of the child */
  height: 40px; /* Set a specific height if needed */
`;

// Element positioned in the far right corner
export const RightCornerElement = styled.div`
  position: absolute; /* Absolute positioning relative to the container */
  top: 50%; /* Center vertically */
  right: 10px; /* Distance from the right edge of the container */
  transform: translateY(-50%); /* Center the element vertically */
`;