import React from 'react';
import { AButton, ButtonText } from './MainButtonStyles';

const MainButton = (props) => {
  return (
    <AButton
      type={props.type}
      onClick={props.onClick}
      color={props.color}
      width={props.width}
      height={props.height}
      disabled={props.disabled}
    >
      {props.icon && (<span style={{marginRight:'8px'}}>{props.icon}</span>)}
      <ButtonText disabled={props.disabled}>
        <b>{props.text}</b>
      </ButtonText>
    </AButton>
  )
}

export default MainButton;
