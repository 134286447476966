import React, {useEffect} from 'react';
import {
    Overlay, 
    PopupBox,
    FullWidthDiv,
    RightCornerElement
} from './PopUpStyles';
import {ReactComponent as X} from '../../images/popup-x.svg';
import BG from '../../images/popup-bg.svg'; 

const PopUp = (props) => {
    useEffect(() => {
        const img = new Image();
        img.src = BG;

        img.onload = () => {
            if (props.onImageLoaded) {
                props.onImageLoaded();
            }
        };
    }, [props]);

    return (
        <Overlay onClick={props.togglePopup}>
            <PopupBox onClick={(e) => e.stopPropagation()}>
                <FullWidthDiv>
                    <RightCornerElement onClick={props.togglePopup}>
                        <X>Click Me</X>
                    </RightCornerElement>
                </FullWidthDiv>
                <p>{props.info}</p>
            </PopupBox>
        </Overlay>
    );
};

export default PopUp;
