import styled, { keyframes } from 'styled-components';

const fadeCycle = keyframes`
    0%, 33.33% {
        opacity: 1;
    }
    33.34%, 66.66% {
        opacity: 0;
    }
    66.67%, 100% {
        opacity: 0;
    }
`;

export const MainDiv = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    position: relative;
    z-index: -1;
`;

export const SmileyContainer = styled.div`
    position: relative;
    width: 89px; 
    height: 89px; 
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -30px;

    svg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: auto;
    }
`;

export const SmileyImage = styled.img`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    opacity: 0; /* Start with all SVGs hidden */
    animation: ${fadeCycle} 1.5s infinite; /* Animation over 3 seconds, looping infinitely */
    
    &:nth-child(1) {
        animation-delay: 0s;
    }

    &:nth-child(2) {
        animation-delay: 0.5s;
    }

    &:nth-child(3) {
        animation-delay: 1s;
    }
`;

export const ScreenshotDiv = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    overflow: hidden;
    padding: 0px;
`;

export const TinyInfoText = styled.p`
    font-size: x-small;
`;

export const Content = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center; /* Horizontally center all child elements */
    height: 100%;
`;

export const EmailInput = styled.input`
    padding: 10px;
    border: 2px solid black;
    border-radius: 20px;
    outline: none;
    width: 250px;
`;

export const Footer = styled.div`
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    text-align: center;
    padding: 10px 0;
`;

export const ALink = styled.a`
    color: black;
`;

export const CongratsText = styled.p`
    color: white;
    font-size: 25px;
`;

export const NameSection = styled.div`
    display: flex;
    flex-direction: column; /* Arrange text vertically */
    padding: 0px 10px;
`;

export const NameText = styled.p`
    font-family: 'ArcadeFont', sans-serif; /* Assuming custom font */
    font-size: 0.8rem; /* Adjust font size */
    margin: 0;
    padding: 0;
    max-width: 20ch;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    background: linear-gradient(90deg, #60BB5F, #229A03);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; 
    background-clip: text; /* For Firefox */
    color: transparent; /* Makes the text itself transparent */
`;

export const WinText = styled.p`
    color: white;
    font-size: 15px;
`;

export const UL = styled.ul`
    list-style-type: disc; /* Default bullet points */
`;

export const LI = styled.li`
    color: white; /* Set text color to white */
    text-align: left; /* Ensure the text is left-aligned */
    margin-bottom: 10px;
    font-size: 9px;
`;

export const CenteredDiv = styled.div`
    display: flex;
    justify-content: center; /* Horizontally center the div */
    align-items: center; /* Vertically center the div */
    height: 100%; /* Make sure the div takes up the full height of the parent */
    margin-right: 20px;
`;