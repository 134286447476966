import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import {
    useNavigate,
    useLocation
} from "react-router-dom";
import { 
    Content, 
    MainDiv, 
    ScreenshotDiv, 
    SmileyContainer,
    CongratsText,
    NameSection,
    NameText,
    WinText,
    UL,
    LI,
    CenteredDiv
} from './SuccessStyles';
import MainButton from '../../components/Buttons/MainButton';
import Logo from "../../components/Logo/Logo";
import Footer from '../../components/Footer/Footer';
import PopUp from "../../components/PopUp/PopUp";
import {ReactComponent as Smiley1} from '../../images/smiley1.svg';
import {ReactComponent as Smiley2} from '../../images/smiley2.svg';
import {ReactComponent as Smiley3} from '../../images/smiley3.svg';
import SuccessImage from '../../images/Floppy.png';

const Success = () => {
    const [currentUser, setCurrentUser] = useState({});
    const navigate = useNavigate();
    const [ locationKeys, setLocationKeys ] = useState([]);
    const location = useLocation();
    const lastOutcome = location.state?.lastOutcome
    const [currentSmiley, setCurrentSmiley] = useState(1);
    const [isPopupVisible, setPopupVisible] = useState(false);
    const [isImageLoaded, setIsImageLoaded] = useState(false);

    const getBaseUrl = useCallback(() => {
        const isProduction = process.env.NODE_ENV === 'production';
        if (isProduction) {
            return 'https://banggz-quiz-5bafa8062821.herokuapp.com';
        } else {
            // Detect if you're accessing from localhost or a local network
            const isLocalhost = window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1';
            return isLocalhost ? 'http://localhost:8000' : 'http://10.0.0.131:8000';
        }
    }, []);

    const navToWelcomePage = useCallback(() => {
        navigate('/');
    }, [navigate]);

    const getCurrentUser = useCallback(() => {
        axios.post(
            `${getBaseUrl()}/api/user/get`,
            {
                user_id: localStorage.getItem("userId").replace(/^"+|"+$/g, ''),
            },
            {withCredentials: true}
        ).then(res => {
            setCurrentUser(res.data);
        }).catch(err => {
            console.log(err);
        });
    }, [getBaseUrl]);

    useEffect(() => {
        if (!locationKeys.includes(location.key)) {
            setLocationKeys([location.key]);
        }

        const handleLocationChange = () => {
            if (window.history.state && window.history.state.idx) {
                if (window.history.state.idx < locationKeys.length) {
                } else {
                }
            }
        };

        window.addEventListener('popstate', handleLocationChange);

        return () => {
            window.removeEventListener('popstate', handleLocationChange);
        };
    }, [locationKeys, location.key]);

    useEffect(() => {
        if(localStorage.getItem("userId") === null){
            navToWelcomePage();
        } else {
            if(lastOutcome === 'success'){
                getCurrentUser();
            } else {
                navToWelcomePage();
            }
        }

        const handleBackNavigation = () => {
            navToWelcomePage(); 
        };

        window.history.pushState(null, '', window.location.href);
        window.addEventListener('popstate', handleBackNavigation);

        return () => {
            window.removeEventListener('popstate', handleBackNavigation);
        };
    }, [navToWelcomePage, getCurrentUser, lastOutcome]);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentSmiley((prev) => (prev === 3 ? 1 : prev + 1));
        }, 500);

        return () => clearInterval(interval);
    }, []);

    const handleDownload = () => {
        const link = document.createElement('a');
        link.href = SuccessImage;

        const isDownloadSupported = 'download' in link;
        if (isDownloadSupported) {
            link.download = '4For4-success.png'; // Use download if supported
        } else {
            link.target = '_blank'; // Open in a new tab if not supported
        }

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const handleImageLoaded = () => {
        setIsImageLoaded(true);
    };

    const trackReceiveButtonClicked = () => {
        window.gtag('event', 'receive_it_button_click', {
            event_category: 'Success',
            event_label: 'Receive It button clicked',
        });
    };

    const receiveIt = () => {
        trackReceiveButtonClicked(); 
        togglePopup();
    };

    const togglePopup = () => {
        setPopupVisible(!isPopupVisible);
    };

    useEffect(() => {
        if (isPopupVisible && isImageLoaded) {
            handleDownload();
        }
    }, [isPopupVisible, isImageLoaded]);

    return (
        <MainDiv>
            <Content>
                {isPopupVisible && (
                    <PopUp 
                        togglePopup={togglePopup}
                        info="Post the downloaded image on social media and tag Banggz (@banggzwaves) using #4FOR4 to win an extra ₦1000"
                        onImageLoaded={handleImageLoaded} 
                    />
                )}
                <ScreenshotDiv>
                    <Logo 
                        width="20rem"
                        $margintop="0rem"
                        $marginbottom="0rem"
                        $bottom="0.5rem"
                        $fontSize="1.69rem"
                    />
                    <br/>
                    <br/>
                    <SmileyContainer>
                        {currentSmiley === 1 && <Smiley1 />}
                        {currentSmiley === 2 && <Smiley2 />}
                        {currentSmiley === 3 && <Smiley3 />}
                    </SmileyContainer>
                    <CongratsText>Congrats!</CongratsText>
                    <NameSection>
                        <NameText>{currentUser.nickname ? currentUser.nickname : "Bangger"}</NameText>
                    </NameSection>
                    <WinText>You won &#8358;4000</WinText>
                </ScreenshotDiv>
                <br/>
                <MainButton
                    color="#FFFFFF"
                    text="Receive It"
                    width="270px"
                    height="40px"
                    type="submit"
                    disabled={false}
                    onClick={receiveIt}
                />
                <br/>
                <CenteredDiv>
                    <UL>
                        <LI>Banggz will contact you via email within 24|hours</LI>
                        <LI>Click on "Receive it"</LI>
                        <LI>Post the downloaded image and tag Banggz with #4for4 to win an extra &#8358;1000</LI>
                    </UL>
                </CenteredDiv>
            </Content>
            <Footer/>
        </MainDiv>
    );
}

export default Success;