import styled from "styled-components";

export const TinyInfoText = styled.p`
    font-size: x-small;
    color: white;
    display: flex; /* Use flexbox to align items */
    align-items: center; /* Vertically center the items */
    justify-content: center; /* Horizontally center the items (optional) */
    margin: 0; /* Remove any default margin */
`;

export const FooterDiv = styled.div`
    // position: fixed;
    // left: 0;
    // bottom: 0;
    width: 100%;
    text-align: center;
    padding: 1.25rem 0rem;
`;

export const ALink = styled.a`
    color: transparent;
    text-decoration: none;
    display: inline-flex;
    align-items: center;
`;
