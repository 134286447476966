import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import {
    useNavigate
} from "react-router-dom";
import { 
    MainDiv, 
    Content,
    UL,
    LI,
    CenteredDiv
} from './WelcomeStyles';
import MainButton from '../../components/Buttons/MainButton';
import Logo from "../../components/Logo/Logo";
import Input from "../../components/Input/Input";
import Footer from '../../components/Footer/Footer';

const Welcome = (props) => {
    const [email, setEmail] = useState("");
    const [success, setSuccess] = useState("");
    const [error, setError] = useState("");
    const navigate = useNavigate();
    const EMAIL_REGEX = /^[\w-.]+@([\w-]+\.)+[\w-]+$/;

    useEffect(() => {
        localStorage.clear();
    }, []);

    useEffect(() => {
        // Replace the current state to ensure back navigation goes back to an external website or not at all
        window.history.replaceState(null, null, window.location.href);
        
        // Function to prevent back navigation
        const disableBackNavigation = (event) => {
            // Prevent going back to your website's previous history entry
            if (event.state === null) {
                window.history.pushState(null, null, window.location.href);
            }
        };

        // Add event listener to monitor history changes
        window.addEventListener('popstate', disableBackNavigation);

        // On unmount, remove event listener
        return () => {
            window.removeEventListener('popstate', disableBackNavigation);
        };
    }, []);

    const getBaseUrl = useCallback(() => {
        const isProduction = process.env.NODE_ENV === 'production';
        if (isProduction) {
            return 'https://banggz-quiz-5bafa8062821.herokuapp.com';
        } else {
            // Detect if you're accessing from localhost or a local network
            const isLocalhost = window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1';
            return isLocalhost ? 'http://localhost:8000' : 'http://10.0.0.131:8000';
        }
    }, []);

    const navToNicknamePage = () => {
        localStorage.setItem('email', JSON.stringify(email));
        navigate('/nickname');
    }

    const navToLoadingPage = (user) => {
        localStorage.setItem('userId', JSON.stringify(user._id));
        navigate('/loading');
    }
    
    function emailValidation(val){
        return EMAIL_REGEX.test(val);
    }

    const onInputChanged = (e) => {
        const name = e.target.name;
        const value = e.target.value;

        switch(name){
            case "email":
                setEmail(value);
                break;
            default:
        }

        if(success.length > 0){setSuccess("");}
        if(error.length > 0){setError("");}
    }

    const trackSubmitButtonClicked = () => {
        window.gtag('event', 'email_submit_button_click', {
          event_category: 'User',
          event_label: 'Email submit button clicked',
        });
    };

    const onSubmitHandler = (e) => {
        e.preventDefault();

        axios.post(
            `${getBaseUrl()}/api/user/getFromEmail`,
            {
                email: email,
            },
            {withCredentials: true}
        ).then(res => {
            trackSubmitButtonClicked();
            res.data.user === null ? 
            navToNicknamePage() : 
            navToLoadingPage(res.data.user)
        }).catch(err => {
            console.log(err);
        });
    }

    return (
        <MainDiv>
            <Content>
                <Logo 
                    width="20rem"
                    $margintop="0rem"
                    $marginbottom="1.88rem"
                    $bottom="2.38rem"
                    $fontSize="1.69rem"
                />
                <br/>
                <br/>
                <form onSubmit={onSubmitHandler}>
                    <Input
                        required
                        name='email'
                        type='email'
                        onChange={onInputChanged}
                        value={email}
                        width='20.13rem'
                        height='2.50rem'
                        $topinfo="ENTER YOUR EMAIL"
                        $othertopinfo="ENTERED EMAIL"
                        $topinfofontsize="0.94rem"
                        $topinfocolor="#C1C1C1"
                    />
                    <br/>
                    <br/>
                    <br/>
                    <MainButton
                        color="#FFFFFF"
                        text="Submit"
                        width="270px"
                        height="40px"
                        type="submit"
                        disabled={!(email && emailValidation(email))}
                    />
                </form>
                <br/>
                <br/>
                <CenteredDiv>
                    <UL>
                        <LI>Answer 4 questions for &#8358;4000</LI>
                        <LI>Listen to the referenced song to answer</LI>
                        <LI>You will be contacted via email within 24|hours</LI>
                    </UL>
                </CenteredDiv>
            </Content>
            <Footer/>
        </MainDiv>
    );
}

export default Welcome;