import styled from "styled-components";

export const TextInput = styled.input`
    padding: 0.63rem;
    border: none;
    border-left: ${props => (props.value && !props.isfocused ? 'none' : '0.06rem solid white')};
    border-right: ${props => (props.value && !props.isfocused ? 'none' : '0.06rem solid white')};
    border-bottom: ${props => (props.value && !props.isfocused ? 'none' : '0.06rem solid white')};
    border-radius: 0.63rem;
    outline: none;
    width: ${props => props.width};
    height: ${props => props.height};
    margin-top: 0.63rem;
    background-color: transparent;
    color: white;
    font-family: 'ArcadeFont', sans-serif;
    font-size: 16px;
    text-align: center;
    white-space: nowrap;
    overflow: hidden; 
    text-overflow: ellipsis;

    &:not(:focus) {
        animation: ${props => (props.value && props.value.length > 10) ? 'scroll-text 5s linear infinite' : 'none'};
    }

    &:-webkit-autofill {
        background-color: transparent !important;
        font-family: 'ArcadeFont', sans-serif !important;
        -webkit-text-fill-color: white !important;
        -webkit-box-shadow: 0 0 0 1000px transparent inset !important;
        box-shadow: 0 0 0 1000px transparent inset !important;
        transition: background-color 5000s ease-in-out 0s;
    }

    &:-webkit-autofill:focus {
        background-color: transparent !important;
        font-family: 'ArcadeFont', sans-serif !important;
        -webkit-text-fill-color: white !important;
        -webkit-box-shadow: 0 0 0 1000px transparent inset !important;
        box-shadow: 0 0 0 1000px transparent inset !important;
        transition: background-color 5000s ease-in-out 0s; /* Prevent background color from flashing */
    }

    &:-webkit-autofill:hover {
        background-color: transparent !important;
        font-family: 'ArcadeFont', sans-serif !important;
        -webkit-text-fill-color: white !important;
        -webkit-box-shadow: 0 0 0 1000px transparent inset !important;
        box-shadow: 0 0 0 1000px transparent inset !important;
        transition: background-color 5000s ease-in-out 0s; /* Prevent background color from flashing */
    }

    &:-moz-autofill {
        font-family: 'ArcadeFont', sans-serif !important;
        background-color: transparent !important;
        -webkit-text-fill-color: white !important;
    }

    &:-ms-autofill {
        font-family: 'ArcadeFont', sans-serif !important;
        background-color: transparent !important;
        -webkit-text-fill-color: white !important;
    }

    &:-o-autofill {
        font-family: 'ArcadeFont', sans-serif !important;
        background-color: transparent !important;
        -webkit-text-fill-color: white !important;
    }
`;

export const TopText = styled.div`
    position: absolute;
    top: 0px;
    left: 50%;
    transform: translateX(-50%);
    background-color: transparent; 
    padding: 0 10px;
    color: ${props => (props.value ? 'white' : props.$topinfocolor)};
    font-size: ${props => props.$topinfofontsize};
    font-family: 'ArcadeFont', sans-serif;
    white-space: nowrap;
    z-index: 1;
`;

export const InputContainer = styled.div`
    position: relative;
    width: 100%;
    background-color: transparent; 

    &:hover ${TopText}, &:focus-within ${TopText} {
        color: ${'white' || '#717F75'};
    }
`;

export const IconContainer = styled.div`
    position: absolute;
    right: 10px; /* Position the icon right next to the last character */
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    color: white;
    pointer-events: none; /* Ensure the icon doesn't interfere with input interaction */
    font-size: 0.69rem; /* Match the icon size to the text size */
`;