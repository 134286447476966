import React from 'react';
import { FooterDiv, ALink, TinyInfoText } from './FooterStyles';
import {ReactComponent as Globe} from '../../images/globe.svg';

const Footer = (props) => {
  const trackLinkClick = () => {
    window.gtag('event', 'footer_clicked', {
      event_category: 'Footer',
      event_label: 'Clicked banggzwaves.com link',
    });
  };

  return (
    <FooterDiv>
        <ALink 
            href='https://www.banggzwaves.com/' 
            target="_blank" 
            rel="noopener noreferrer"
            onClick={trackLinkClick}
        >
            <TinyInfoText>
                <Globe width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"/>
                &nbsp;banggzwaves.com
            </TinyInfoText>
        </ALink>
    </FooterDiv>
  )
}

export default Footer;
