import styled from "styled-components";

export const MainDiv = styled.div`
    display: flex;
    flex-direction: column;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin: 20px;
`;

export const Content = styled.div`
    flex: 1; /* This allows the content to grow and take up available space */
`;

export const UL = styled.ul`
    list-style-type: disc; /* Default bullet points */
`;

export const LI = styled.li`
    color: white; /* Set text color to white */
    text-align: left; /* Ensure the text is left-aligned */
    margin-bottom: 10px;
    font-size: 9px;
`;

export const CenteredDiv = styled.div`
    display: flex;
    justify-content: center; /* Horizontally center the div */
    align-items: center; /* Vertically center the div */
    height: 100%; /* Make sure the div takes up the full height of the parent */
    margin-right: 20px;
`;