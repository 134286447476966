import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import {
    useNavigate
} from "react-router-dom";
import { 
    MainDiv, 
    Content,
    UL,
    LI,
    CenteredDiv
} from './NicknameStyles';
import MainButton from '../../components/Buttons/MainButton';
import Logo from "../../components/Logo/Logo";
import Input from "../../components/Input/Input";
import Footer from '../../components/Footer/Footer';

const Nickname = (props) => {
    const [nickname, setNickname] = useState("");
    const [email, setEmail] = useState("");
    const [success, setSuccess] = useState("");
    const [error, setError] = useState("");
    const navigate = useNavigate();

    const navToLoadingPage = () => {
        navigate('/loading');
    }

    const navToWelcomePage = useCallback(() => {
        navigate('/');
    }, [navigate]);

    useEffect(() => {
        localStorage.getItem("email") === null ? navToWelcomePage() : setEmail(localStorage.getItem("email").replace(/^"+|"+$/g, ''));
    }, [navToWelcomePage]);

    const getBaseUrl = useCallback(() => {
        const isProduction = process.env.NODE_ENV === 'production';
        if (isProduction) {
            return 'https://banggz-quiz-5bafa8062821.herokuapp.com';
        } else {
            // Detect if you're accessing from localhost or a local network
            const isLocalhost = window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1';
            return isLocalhost ? 'http://localhost:8000' : 'http://10.0.0.131:8000';
        }
    }, []);

    const onInputChanged = (e) => {
        const name = e.target.name;
        const value = e.target.value;

        switch(name){
            case "nickname":
                setNickname(value);
                break;
            default:
        }

        if(success.length > 0){setSuccess("");}
        if(error.length > 0){setError("");}
    }

    const trackSubmitButtonClicked = () => {
        window.gtag('event', 'nickname_submit_button_click', {
          event_category: 'User',
          event_label: 'Nickname submit button clicked',
        });
    };

    const onSubmitHandler = (e) => {
        e.preventDefault();

        axios.post(
            `${getBaseUrl()}/api/user/create`,
            {
                nickname: "4TB|" + nickname,
                email: email
            },
            {withCredentials: true}
        ).then(res => {
            trackSubmitButtonClicked();
            localStorage.clear();
            localStorage.setItem("userId", JSON.stringify(res.data._id));
            navToLoadingPage();
        }).catch(err => {
            console.log(err);
        });
    }

    return (
        <MainDiv>
            <Content>
                <Logo 
                    width="20rem"
                    $margintop="0rem"
                    $marginbottom="1.88rem"
                    $bottom="2.38rem"
                    $fontSize="1.69rem"
                />
                <br/>
                <br/>
                <form onSubmit={onSubmitHandler}>
                    <Input
                        required
                        name='nickname'
                        type='text'
                        onChange={onInputChanged}
                        value={nickname}
                        width='20.13rem'
                        height='2.50rem'
                        $topinfo="CREATE A NICKNAME"
                        $othertopinfo="ENTERED NICKNAME"
                        $topinfofontsize="0.94rem"
                        $topinfocolor="#C1C1C1"
                    />
                    <br/>
                    <br/>
                    <br/>
                    <MainButton
                        color="#FFFFFF"
                        text="Submit"
                        width="270px"
                        height="40px"
                        type="submit"
                        disabled={nickname ? false : true}
                    />
                </form>
                <br/>
                <br/>
                <CenteredDiv>
                    <UL>
                        <LI>Answer 4 questions for &#8358;4000</LI>
                        <LI>Listen to the referenced song to answer</LI>
                        <LI>You will be contacted via email within 24|hours</LI>
                    </UL>
                </CenteredDiv>
            </Content>
            <Footer/>
        </MainDiv>
    );
}

export default Nickname;