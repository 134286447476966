import React, { useEffect, useCallback } from 'react';
import {
    useNavigate
} from "react-router-dom";
import { 
    MainDiv, 
    Content,
    UL,
    LI,
    CenteredDiv,
    StyledIframe,
    LogoDiv
} from './LoadingStyles';
import Logo from "../../components/Logo/Logo";
import Footer from '../../components/Footer/Footer';
import loadingAnimation from '../../animations/loadingAnimation.json';

const Loading = (props) => {
    const navigate = useNavigate();

    const navToQuizPage = useCallback(() => {
        navigate('/quiz');
    }, [navigate]);

    const navToWelcomePage = useCallback(() => {
        navigate('/');
    }, [navigate]);

    useEffect(() => {
        localStorage.getItem("userId") === null && navToWelcomePage()
    }, [navToWelcomePage]);

    useEffect(() => {
        // Prevent back navigation
        window.history.replaceState(null, null, window.location.href);
        window.history.pushState(null, null, window.location.href);

        const disableBackNavigation = () => {
            window.history.pushState(null, null, window.location.href);
        };

        window.addEventListener('popstate', disableBackNavigation);

        // Cleanup on component unmount
        return () => {
            window.removeEventListener('popstate', disableBackNavigation);
        };
    }, []);

    useEffect(() => {
        const timer = setTimeout(() => {
            navToQuizPage();
        }, 2000);
    
        return () => clearTimeout(timer);
    }, [navToQuizPage]);

    return (
        <MainDiv>
            <Content>
                <LogoDiv>
                    <Logo 
                        width="20rem"
                        $margintop="5rem"
                        $marginbottom="1.88rem"
                        $bottom="2.8rem"
                        $fontSize="1.3rem"
                    />
                </LogoDiv>
                <br/>
                <br/>
                <br/>
                <StyledIframe
                    autoplay
                    loop
                    src={loadingAnimation}
                />
                <CenteredDiv>
                    <UL>
                        <LI>Answer 4 questions for &#8358;4000</LI>
                        <LI>Listen to the referenced song to answer</LI>
                        <LI>You will be contacted via email within 24|hours</LI>
                    </UL>
                </CenteredDiv>
            </Content>
            <Footer/>
        </MainDiv>
    );
}

export default Loading;